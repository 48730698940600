export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Marketing Agency',
    headline: 'Lead Generation Specialist for Online Businesses',
    description:
      'We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals.',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };

  export const businessSuiteOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'All-in-one Suite',
    headline: 'Business Suite',
    descriptionOne: "A comprehensive Business Suite, offering innovative solutions for fresh produce businesses and B2B customers to effectively manage and exponentially expand their operations.",
    descriptionTwo: "Transformative! This Sabzland Suite has revolutionized our operations, enabling us to manage and scale our fresh produce business like never before.",
    buttonLabel: 'Learn More',
    imgStart: '',
    imgone: require('../../images/icons/comma.png'),
    imgtwo: require('../../images/sabzland_white_logo.png'),
    testimonialimgOne: require('../../images/ceodoodifoods.png'),
    testimonialName: "Zaheer Ahmed",
    testimonialDesignation: "Chief Executive Ofiicer",
    testimonialCompany: "Doodi Seafood",
    alt: 'Testimonial',
    start: ''
  };

  export const sustainableOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'All-in-one Suite',
    headline: 'Sustainable B2B',
    description: "Technology for a greener future. Optimize efficiency, reduce waste, and build a more responsible supply chain.", 
    textOne: "100+ Business",
    textTwo: "Worldwide",
    textThree: "4+ Countries",
    textFour: "Adding more every month",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/sustainable.png'),
    alt: 'sabzland sustainable',
    start: ''
  };

  export const sustainableOne1 = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Sustainable',
    headline: 'Lead Generation Specialist for Online Businesses',
    description:
      'We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals.',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card',
    start: ''
  };

  export const agricultureOne = {
    primary: true,
    lightBg: false,
    lightTopLine: false,
    lightText: true,
    lightTextDesc: true,
    icon: true,
    topLine: 'Empowering Agriculture',
    headline: 'Your One-Stop Shop for Success',
    descriptionOne:
      'Connectpur is transforming the agriculture sector by empowering farmers and agricultural businesses. We offer a unique combination of features, all in one place.',
    buttonLabel: 'Download App',
    imgStart: '',
    img: require('../../images/sabzlandApp/sabzland_app.png'),
    alt: 'Connectpur',
    start: ''
  };

  export const businessSuiteTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    icon: false,
    topLine: 'All-in-one Suite',
    headline: 'Sabzland Business Suite',
    descriptionOne:
      "Sabzland empowers B2B companies to thrive in today's dynamic market.",
    descriptionTwo:
      "We offer a comprehensive suite of business solutions designed to optimize operations, increase efficiency, and maximize profitability throughout the entire supply chain.",
    buttonLabel: 'Book Session',
    imgStart: '',
    img: require('../../images/businessSuiteTwo.png'),
    alt: 'Sabzland Business Suite',
    start: ''
  };

  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Setup',
    headline: 'Extremely quick onboarding process',
    description:
      "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Sarah Jeni',
    headline:
      'Ultra helped me increase my revenue by over 3X in less than 3 months!',
    description:
      "Their team is wonderful! I can't believe I didn't start working with them earlier.",
    buttonLabel: 'View Case Study',
    imgStart: 'start',
    // img: require('../../images/profile.jpg'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    start: 'true'
  };