export const businessSuiteTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  icon: false,
  button: true,
  topLine: 'All-in-one Suite',
  headline: 'Sabzland Business Suite',
  descriptionOne:
    "Sabzland empowers B2B companies to thrive in today's dynamic market.",
  descriptionTwo:
    "We offer a comprehensive suite of business solutions designed to optimize operations, increase efficiency, and maximize profitability throughout the entire supply chain.",
  buttonLabel: 'Book Session',
  imgStart: '',
  img: require('../../images/businessSuiteTwo.png'),
  alt: 'Sabzland Business Suite',
  start: '',
  linked: '/contact'
};

export const businessSuiteThree = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'All-in-one Suite',
  headingTopOne: 'Why',
  headingTopTwo: 'Sabzland',
  headlineOne: '30%',
  headlineTwo: '100%',
  headlineThree: 'Scales',
  descriptionOne: "Increase operational efficiency by up to 30% with our integrated Sabzland Business Suite.",
  descriptionTwo: "Achieve 100% transparency across your entire operations with real-time tracking and analytics",
  descriptionThree: "Sabzland scales with your business, supporting growth allowing you to focus on what matters most - growing your business",
  buttonLabel: 'Learn More',
  imgStart: '',
  imgone: require('../../images/icons/comma.png'),
  imgtwo: require('../../images/sabzland_white_logo.png'),
  testimonialimgOne: require('../../images/icons/comma.png'),
  testimonialName: "Zaheer Ahmed",
  testimonialDesignation: "Chief Executive Ofiicer",
  testimonialCompany: "Doodi Seafood",
  alt: 'Testimonial',
  start: ''
};

export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headline: 'Unlimited Transactions with zero fees',
    description:
      'Get access to our exclusive diamond card that allows you to  send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '100% Secure',
    headline: 'Stay protected 24/7 anywhere anytime',
    description:
      'We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Easy Setup',
    headline: 'Super fast and simple onboarding process',
    description:
      "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Data Analytics',
    headline: 'Every transaction is stored on our secure cloud database',
    description:
      'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-1.svg'),
    alt: 'Vault'
  };