import React from 'react';
import { Privacy } from '../../components';

const PrivacyPage = () => {
    return (
        <>
            <Privacy />
        </>
    )
}

export default PrivacyPage;