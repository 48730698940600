export const agricultureOne = {
  primary: true,
  lightBg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  icon: true,
  button: true,
  topLine: 'Empowering Agriculture',
  headline: 'Your One-Stop Shop for Success',
  descriptionOne:
    'Connectpur is transforming the agriculture sector by empowering farmers and agricultural businesses. We offer a unique combination of features, all in one place.',
  buttonLabel: 'Download App',
  imgStart: '',
  img: require('../../images/sabzlandApp/sabzland_app.png'),
  alt: 'Connectpur',
  start: '',
  linked: 'https://play.google.com/store/apps/details?id=com.connectpur.app'
};

export const agricultureTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  icon: false,
  button: false,
  topLine: '',
  headline: 'AI Driven - Market Analysis & Insights',
  descriptionOne:
    'Gain insights into future trends, pricing fluctuations, and crop demand to optimize decision strategies and secure better profits.', 
  buttonLabel: '',
  imgStart: '',
  img: require('../../images/sabzlandApp/sabzland_app.png'),
  alt: 'Connectpur',
  start: ''
};

export const agricultureThree = {
  primary: true,
  lightBg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  button: false,
  icon: false,
  topLine: '',
  headline: 'Thriving Community',
  descriptionOne:
    'Connect with a network of farmers, experts, and peers. Share knowledge, ask questions, and gain valuable insights to improve your agricultural practices.',
  buttonLabel: '',
  imgStart: 'start',
  img: require('../../images/sabzlandApp/sabzlandCommunity.png'),
  alt: 'Connectpur',
  start: 'true'
};

export const agricultureFour = {
  primary: true,
  lightBg: false,
  lightTopLine: false,
  lightText: true,
  lightTextDesc: true,
  icon: false,
  button: false,
  topLine: '',
  headline: 'Streamlined Trade Platform',
  descriptionOne:
    'Buy and sell agricultural products with ease. Our platform offers a wider market reach, secure transactions, and real-time market data, allowing you to make informed decisions and maximize your profits.',
  buttonLabel: '',
  imgStart: 'start',
  img: require('../../images/sabzlandApp/sabzlandFindSeller.png'),
  alt: 'Connectpur',
  start: 'true'
};