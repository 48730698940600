import React from 'react';
import { InfoSection, Contact, ContactForm } from '../../components';
import { homeObjOne } from './Data';

function Contacts() {
  return (
    <>
      <Contact />
    </>
  );
}

export default Contacts;